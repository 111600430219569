.mat-mdc-text-field-wrapper {
  @apply flex items-center rounded-md bg-white pl-3 text-sm shadow-sm #{!important};

  &:not(:has(.mat-mdc-form-field-textarea-control)):not(:has(mat-chip-grid)) {
    @apply h-9 #{!important};
  }

  &:has(.mat-mdc-form-field-textarea-control) {
    @apply pr-2 #{!important};
  }

  &.mdc-text-field--outlined .mat-mdc-form-field-infix {
    align-items: center;
  }
}

.mat-mdc-form-field-infix {
  min-height: unset;
  display: flex !important;
  padding: 0px !important;

  &:has(.mat-mdc-form-field-textarea-control) {
    @apply py-2 #{!important};
  }
}

.mat-mdc-form-field-flex {
  align-items: center !important;
}

.mdc-notched-outline__leading {
  @apply rounded-l-md #{!important};
}

.mdc-notched-outline__trailing {
  @apply rounded-r-md #{!important};
}

.mdc-button {
  @apply h-9 min-w-fit rounded-md text-sm shadow-sm #{!important};
  mat-icon {
    @apply mb-0.5 scale-[80%] p-0 #{!important};
  }

  &.mat-unthemed {
    @apply shadow-none #{!important};
  }
}

.mdc-button__label:has(ng-icon) {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: -5px;
}

mat-error {
  @apply text-xs text-red-500 #{!important};
}

mat-hint {
  @apply -ml-1 text-xs text-gray-500 #{!important};
}

mat-button-toggle-group {
  @apply flex h-9 rounded-md  #{!important};

  mat-button-toggle {
    @apply flex h-full flex-1  items-center  text-sm  #{!important};
    &.mat-button-toggle-checked {
    }
  }
}

mat-option {
  @apply py-2 text-sm #{!important};
}

// start mat-chips

mat-chip {
  @apply flex h-8 gap-0.5 bg-gray-100 pl-0 pr-2 text-xs font-[500] text-slate-500 #{!important};

  mat-icon {
    @apply scale-[80%] p-0 #{!important};
  }
}

mat-chip-option {
  @apply text-xs #{!important};

  *,
  ::before,
  ::after {
    @apply border-gray-200 #{!important};
  }
}

// remove check icon
.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic
  .mdc-evolution-chip__graphic {
  @apply hidden #{!important};
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic
  .mdc-evolution-chip__action--primary {
  @apply pl-3 #{!important};
}

.mat-mdc-chip-action-label {
  @apply flex items-center gap-1 #{!important};
}

// selected
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  @apply bg-blue-600 text-white  #{!important};
}

// not selected
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  @apply bg-gray-100  #{!important};
}

// end mat-chips

.mat-mdc-form-field-error-wrapper {
  @apply pl-2 #{!important};
}

.mdc-icon-button {
  @apply flex h-10 w-10 items-center justify-center #{!important};
}

.mdc-tooltip {
  @apply text-xs #{!important};
}

mat-checkbox {
  margin-left: -12px;

  .mdc-checkbox {
    scale: 90%;
    margin-right: -5px;

    .mdc-checkbox__background {
      border-radius: 4px;
    }
  }
}

.mat-mdc-option {
  min-height: 40px !important;
}

.mat-mdc-paginator {
  @apply text-sm text-gray-700 #{!important};
}
