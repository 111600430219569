.ab-skeleton-wrapper {
  position: relative;
  overflow: hidden;
}

.ab-skeleton-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent,
    rgba(0, 0, 0, 0.1),
    transparent
  );
  animation: ab-skeleton-loading 1.5s infinite;
}

@keyframes ab-skeleton-loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
