.error-snackbar {
  .mdc-snackbar__surface {
    @apply bg-red-600  #{!important};
  }
}

.success-snackbar {
  .mdc-snackbar__surface {
    @apply bg-blue-600  #{!important};
  }
}

.warning-snackbar {
  .mdc-snackbar__surface {
    @apply bg-yellow-500 text-black #{!important};
  }
  .mat-mdc-snack-bar-label {
    @apply text-gray-900 #{!important};
  }
}
