/* You can add global styles to this file, and also import other style files */
@use "scss/ab";
@use "scss/am-components/am-dialog";
@use "scss/am-components/am-form-components";
@use "scss/am-components/am-snackbar";
@use "scss/am-components/am-spinner";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Play:wght@400;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "azure-maps-drawing-tools/dist/atlas-drawing.min.css";

@tailwind base;
@layer base {
  // manter o preflight de bordas do tailwind
  *,
  ::before,
  ::after {
    border-width: 0;
    border-style: solid;
    border-color: theme("borderColor.DEFAULT", currentColor);
  }
}
@tailwind components;
@tailwind utilities;

:root {
  --vh: 1vh;
}

:root {
  --app-height: 100%;
}

html,
body {
  @apply fixed m-0 box-border w-full overflow-hidden bg-neutral-50 p-0;
  height: 100vh; /* Fallback */
  height: var(--app-height);
}

app-root {
  @apply block h-full w-full overflow-hidden;
}

nz-layout {
  @apply h-full;
}

nz-content {
  @apply overflow-auto;
}

input::placeholder {
  @apply text-sm;
}

.cdk-text-field-autofill-monitored {
  @apply text-sm;
}

/* Estilizando a barra de rolagem */
::-webkit-scrollbar {
  @apply h-1 w-1;
}

/* Estilizando o trilho (fundo) da barra de rolagem */
::-webkit-scrollbar-track {
  @apply bg-neutral-300;
}

/* Estilizando o "polegar" (o controle deslizante) da barra de rolagem */
::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
}

/* Estilizando o "polegar" enquanto está sendo arrastado */
::-webkit-scrollbar-thumb:hover {
  @apply bg-neutral-500;
}

// AzureMaps: Remove bordas e fundo desnecessário do botão do draw toolbar
.azure-maps-drawtoolbar-button {
  @apply border-none bg-transparent;
}

.ant-skeleton-input {
  @apply size-full #{!important};
}

.ant-popover-message-title {
  @apply ml-2;
}

.ant-popover-message {
  .anticon {
    @apply text-amber-500;
  }
}
